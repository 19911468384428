import axios from 'axios'

const apiBaseURL = process.env.REACT_APP_API_URL;

export default class BackendService {

    static getAuthToken() {
        return sessionStorage.getItem('authToken');
    }
    //AUTH
    static authenticate(user, pw) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + '/auth',
                    method: 'post',
                    data: {
                        'benutzername': user,
                        'passwort': pw
                    }
                }
            )
                .then(response => {
                    sessionStorage.setItem('authToken', response.data.auth_token);
                    sessionStorage.setItem('user', JSON.stringify(response.data.user));
                    resolve(response);
                })
                .catch(error => {
                    reject(error)
                });
        });

    }

    static changePasswordOfUser(data) {
        return BackendService.patch('/auth/admin', data);
    }

    //VA
    static getVeranstaltungen(id = null) {
        let route = ''
        // Trailing slash leeds to 301 redirect
        // this redirects to http instead of origin
        // which causes mixed content
        // so.... no VA 
        if (id) {
            route = '/veranstaltung/' + id
        } else {
            route = '/veranstaltung'
        }
        return BackendService.get(route);
    }

    static editVeranstaltungen(data) {
        return BackendService.put('/veranstaltung', data);
    }

    static createVeranstaltungen(data) {
        return BackendService.post('/veranstaltung', data);
    }

    static getVAArten() {
        return BackendService.get('/veranstaltungsarten');
    }

    static getVATypen() {
        return BackendService.get('/veranstaltungstypen');
    }

    static getVAUsers(id) {
        return BackendService.get('/veranstaltungsbenutzer/' + id);
    }

    static createVAUser(va, id) {
        return BackendService.post('/veranstaltungsbenutzer', {va: va, benutzer: id, sort: null, rausgenommen: null});
    }

    static deleteVAUser(va, id) {
        return BackendService.delete('/veranstaltungsbenutzer/' + va + '/' + id);
    }

    static getVAIcon(vaID) {
        return BackendService.getImage('/veranstaltung/' + vaID + '/preview')
    }

    static createVAIcon(vaID, data) {
        return BackendService.post('/veranstaltung/' + vaID + '/preview', data)
    }

    static editVAIcon(vaID, data) {
        return BackendService.put('/veranstaltung/' + vaID + '/preview', data)
    }

    static deleteVAIcon(vaID) {
        return BackendService.delete('/veranstaltung/' + vaID + '/preview')
    }

    static copyVA(data) {
        return BackendService.post('/veranstaltung/copy', data)
    }

    //User
    static getUser(id) {
        return BackendService.get('/user/' + id);
    }
    
    static getUsers() {
        return BackendService.get('/user');
    }

    static createUser(data) {
        return BackendService.post('/user', data);
    }

    static editUser(data) {
        return BackendService.put('/user', data);
    }

    static deleteUser(id) {
        return BackendService.delete('/user', {benutzer_id: id});
    }

    //User File
    static getUserFile(id) {
        return BackendService.getImage('/user/' + id + '/preview');
    }

    static createUserFile(id, data) {
        return BackendService.post('/user/' + id + '/preview', data);
    }

    static deleteUserFile(id, data) {
        return BackendService.delete('/user/' + id + '/preview', data);
    }

    //Checklist
    static getChecklistElements(va, typ) {
        return BackendService.get('/checklistelements/' + va + '/' + typ);
    }

    static editChecklistElement(data) {
        return BackendService.put('/checklistelements', data);
    }

    static createChecklistElement(data) {
        return BackendService.post('/checklistelements', data);
    }

    static printChecklist(data) {
        return BackendService.postPDF('/checklistelements/print', data);
    }

    static getBemerkungen(id) {
        return BackendService.get('/checklistelement/' + id + '/bemerkungen');
    }

    static checkHoliday(date) {
        return BackendService.get('/is-holiday/' + date);
    }

    static createBemerkung(id, data) {
        return BackendService.post('/checklistelement/' + id + '/bemerkungen', data);
    }

    static updateBemerkung(id, data) {
        return BackendService.post('/checklistelement/' + id + '/bemerkungen/update', data);
    }

    static sortChecklistElement(data) {
        return BackendService.post('/checklistelements/sort', data)
    }

    //Beteiligte
    static getBeteiligte(va) {
        return BackendService.get('/beteiligte/veranstaltung/' + va);
    }

    static getBeteiligterById(id) {
        return BackendService.get('/beteiligte/' + id);
    }

    static deleteBeteiligter(data) {
        return BackendService.delete('/beteiligter', data);
    }

    static editBeteiligter(data) {
        return BackendService.put('/beteiligter', data);
    }

    static createBeteiligter(va, data) {
        return BackendService.post('/beteiligte/' + va, data);
    }

    static sortBeteiligte(va, body) {
        return BackendService.put('/veranstaltung/' + va + '/beteiligte/sort', body);
    }

    static printBeteiligte(id, data) {
        return BackendService.postPDF('/beteiligte/veranstaltung/' + id + '/print', data)
    }

    //Beteiligte File
    static getBeteiligterFile(id) {
        return BackendService.getImage('/beteiligte/' + id + '/preview');
    }

    static createBeteiligterFile(id, data) {
        return BackendService.post('/beteiligte/' + id + '/preview', data);
    }

    static deleteBeteiligterFile(id, data) {
        return BackendService.delete('/beteiligte/' + id + '/preview', data);
    }

    //Werbemittel
    static getWerbemittelByVA(va) {
        return BackendService.get('/wmittel/veranstaltung/' + va);
    }

    static createWerbemittel(data) {
        return BackendService.post('/wmittel', data);
    }

    static editWerbemittel(data) {
        return BackendService.put('/wmittel', data);
    }

    static deleteWerbemittel(data) {
        return BackendService.delete('/wmittel', data);
    }

    static copyWerbemittel(id) {
        return BackendService.copy('/wmittel/' + id);
    }
    //Werbemittel File
    static getWerbemittelFile(index) {
        return BackendService.getImage('/wmittel/preview/' + index);
    }

    static createWerbemittelFile(id, data) {
        return BackendService.post('/wmittel/' + id + '/preview', data);
    }

    static deleteWerbemittelFile(id) {
        return BackendService.delete('/wmittel/preview/' + id);
    }

    static editWerbemittelFile(id, data) {
        return BackendService.put('/wmittel/preview/' + id, data);
    }

    static printWerbemittel(id, data) {
        return BackendService.postPDF('/wmittel/veranstaltung/' + id + '/print', data)
    }

    //Ausruestung
    static getAusruestungByVA(va) {
        return BackendService.get('/ausruestung/veranstaltung/' + va);
    }

    static createAusruestung(data) {
        return BackendService.post('/ausruestung', data);
    }

    static editAusruestung(data) {
        return BackendService.put('/ausruestung', data);
    }

    static deleteAusruestung(data) {
        return BackendService.delete('/ausruestung', data);
    }

    static copyAusruestung(id) {
        return BackendService.copy('/ausruestung/' + id);
    }

    static printAusruestung(id, data) {
        return BackendService.postPDF('/ausruestung/veranstaltung/' + id + '/print', data)
    }
    //Ausruestung File
    static getAusruestungFile(index) {
        return BackendService.getImage('/ausruestung/preview/' + index);
    }

    static createAusruestungFile(id, data) {
        return BackendService.post('/ausruestung/' + id + '/preview', data);
    }

    static deleteAusruestungFile(id) {
        return BackendService.delete('/ausruestung/preview/' + id);
    }

    static editAusruestungFile(id, data) {
        return BackendService.put('/ausruestung/preview/' + id, data);
    }

    //Checkmaster
    static getCheckmasterElementsByListType(type, VAType) {
        return BackendService.get('/checkmaster/listentyp/' + type);
    }
    static createCheckmasterElement(data) {
        return BackendService.post('/checkmaster', data);
    }
    static editCheckmasterElement(data) {
        return BackendService.put('/checkmaster', data);
    }
    static deleteCheckmasterElement(id) {
        return BackendService.delete('/checkmaster', {id});
    }
    static printCheckmaster(listTyp, vaTyp, data) {
        return BackendService.postPDF('/checkmaster/listentyp/' + listTyp + '/veranstaltungstyp/' + vaTyp + '/print', data)
    }

    //Groups
    static getGroups() {
        return BackendService.get('/gruppen')
    }
    static setGroups(data) {
        return BackendService.post('/user/gruppen', data)
    }

    //Roles
    static getRoles() {
        return BackendService.get('/rollen')
    }
    static setRoles(data) {
        return BackendService.post('/user/rollen', data)
    }

    //Help
    static getHelp() {
        return BackendService.get('/help')
    }

    static editHelp(data) {
        return BackendService.put('/help', data)
    }

    //FAQ
    static getFAQ() {
        return BackendService.get('/faq')
    }

    static createFAQ(data) {
        return BackendService.post('/faq', data)
    }

    static editFAQ(data) {
        return BackendService.put('/faq', data)
    }

    static deleteFAQ(id) {
        return BackendService.delete('/faq', {id: id})
    }

    static printFAQ() {
        BackendService.getPDF('/faq/print')
    }

    //Feedback
    static getFeedback() {
        return BackendService.get('/feedback')
    }

    static createFeedback(data) {
        return BackendService.post('/feedback', data)
    }

    static deleteFeedback(id) {
        return BackendService.delete('/feedback', {id: id})
    }

    //Reminder
    static getReminder(va) {
        return BackendService.get('/reminder/' + va)
    }

    static deleteReminder(clid) {
        return BackendService.delete('/reminder', {clid})
    }

    static deleteAllReminder(va) {
        return BackendService.delete('/reminder/all', {va})
    }

    //Todo
    static getTodos() {
        return BackendService.get('/checklistelements/todo')
    }

    static getVAs() {
        return BackendService.get('/veranstaltung/user/')
    }

    static printTodos(data) {
        return BackendService.postPDF('/checklistelements/todo/print', data);
    }

    //____________
    static post(route, body) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'post',
                    data: body,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                }
            )
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }
    
    static get(route, config) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    ...config,
                    url: apiBaseURL + route,
                    method: 'get',
                    headers: {
                        //'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken(),
                    },
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static delete(route, data) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                    data
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static put(route, body) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                    data: body
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static copy(route, config) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    ...config,
                    url: apiBaseURL + route,
                    method: 'copy',
                    headers: {
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static patch(route, body) {
        return new Promise((resolve, reject) => {
            axios(
                {
                    url: apiBaseURL + route,
                    method: 'patch',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-AUTH-TOKEN': BackendService.getAuthToken()
                    },
                    data: body
                })
                .then(data => {
                    resolve(data.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        BackendService.logoutCallback();
                        reject(error);
                    }
                    else {
                        reject(error);
                    }
                });
        });
    }

    static getImage(route) {
        return new Promise((resolve, reject) => {
            BackendService.get(route, {responseType: 'blob'})
            .then(data => {
                var reader = new FileReader();
                reader.onload = function () {
                    var b64 = reader.result.replace(/^data:.+;base64,/, '');
                    if(b64 != "data:" && data.size > 0){
                        resolve('data:image/png;base64,' + b64);
                    }else{
                        //image not found 
                        resolve('/imgs/Image-Placeholder.jpeg');
                    }
                };
                reader.readAsDataURL(data);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    static getPDF(route) {
        return BackendService.get(route).then(pdf => {
            //Create a Blob from the PDF Stream
            // const file = new Blob(
            //     [pdf],
            //     { encoding:"UTF-8", type: 'application/pdf;charset=UTF-8' }
            // );
            //Build a URL from the file
            //const base64Content = btoa(unescape(encodeURIComponent(pdf)));


            const fileURL = 'data:application/pdf;base64,' + pdf;
            const blob = dataURItoBlob(fileURL);

            //Open the URL on new Window
            window.open(URL.createObjectURL(blob), '_blank');
        })
            .catch(error => {
                console.log(error);
            });
    }

    static postPDF(route, data) {
        return BackendService.post(route, data).then(pdf => {
            const fileURL = 'data:application/pdf;base64,' + pdf;
            const blob = dataURItoBlob(fileURL);

            window.open(URL.createObjectURL(blob), '_blank');
        })
            .catch(error => {
                console.log(error);
            });
    }

}

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], {type: mimeString});
    return blob;
}
